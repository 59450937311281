import React, { useState, useEffect } from 'react';

import './UsDebt.css';
import usDebtData from '../../us_debt.json';
import Gary from './gary.png';
import meme from './meme.webp';

const UsDebt = () => {
//   const [usDebt, setUsDebt] = useState(usDebtData.usDebt);
  const [usDebt, setUsDebt] = useState(0);

  useEffect(() => {
    const fetchUsDebtData = async () => {
      try {
        const response = await fetch('/us_debt.json');
        const data = await response.json();
        setUsDebt(data.usDebt);
      } catch (error) {
        console.error('Error fetching us_debt.json:', error);
      }
    };
  
    fetchUsDebtData();
  
    const interval = setInterval(() => {
      const randomDecimal = Math.floor(Math.random() * 100);
      setUsDebt((prevDebt) => prevDebt + 2500 + randomDecimal);
    }, 100);
  
    return () => clearInterval(interval);
  }, []);

  function redirectToExternalPage(url) {
    window.open(url, '_blank');
  }
  

  return (
    <div className="container">
    <h1>GARY GENSLER COIN</h1>
    <div className="counter-container">
      <div className="counter-box">
        <h2>US National Debt</h2>
        <p style={{marginTop:'1em'}}>${usDebt.toLocaleString()}</p>
        <div style={{textAlign:'center'}}>US INFLATION MAKES YOU POORER</div>
      </div>
      <div className="counter-box">
        <h2 style={{backgroundColor:'green'}}>GGc Circulating Supply</h2>
        <p style={{marginTop:'1em', borderColor:'green'}}>{usDebt.toLocaleString()}</p>
         <div style={{textAlign:'center'}}>GGC INFLATION IS SPREAD AMONGST HOLDERS</div>
      </div>
      <img src={Gary} alt="Bouncing Image" className="bouncing-image" />

     
    </div>


    <div className="buttons-container">
      <button className="trade-button" onClick={() => redirectToExternalPage('https://www.uniswap.com')}>UNISWAP</button>
      <button className="redeem-button" onClick={() => redirectToExternalPage('https://www.redeem.com')}>REDEEM</button>
    </div>

    <div className="explanation">
        <div className="expbox">
            The US Dollar, despite its dominance in the global market, is facing challenges due to its dependence on an increasingly outdated financial system. This began with the establishment of central banks after World War I and was further exacerbated by the end of the gold standard in 1971, which allowed a select group of central bankers to control the money system. The 2008 financial crisis revealed the fragility of this system, as unprecedented quantitative easing measures were introduced to stabilize the economy. Unfortunately, these actions often benefited the wealthy at the expense of ordinary citizens, leading to growing wealth inequality and reduced purchasing power.
            Political leaders have been known to make lofty promises to voters, which can result in deficit spending and mounting national debt. This borrowed currency often finds its way into the pockets of corporations, corrupt politicians, or as bailouts for irresponsible banks who gamble with other peoples money. Meanwhile, ordinary citizens are burdened with repaying this debt through various taxes, some completely ridiculous such as property tax (You don't own property if you have to pay rent to goverment).
        </div>

        <img src={meme} alt="meme" className="memeimg" style={{width:'20em', margin:'1em auto'}}/>

        <div className="expbox">
        In contrast, cryptocurrencies like Bitcoin offer a more equitable and efficient system for preserving purchasing power and ensuring a fair distribution of resources. Gary Gensler Coin (GGc) is a meme crypto designed to critique figures like Gary Gensler and the shortcomings of the traditional financial system. The supply of GGc will be tied to the US National debt, and each week, the inflation will be redistributed among all GGc holders. GGc coins can be redeemed for free on the Polygon network, providing a more cost-effective alternative to Ethereum.
        </div>

       <button className='telegram' onClick={() => redirectToExternalPage('https://t.me/+8Vkjig23sQw2ZmZi')}> TELEGRAM </button>

    </div>


  </div>
  );
};

export default UsDebt;
