import React from 'react';
import news_articles from '../../news_articles.json';
import './NewsPage.css';

import Gary from '../Tokenomics/gary.png';


const NewsPage = () => {
  return (
    <div className="container">
      <h1>News</h1>
      <div className="news-grid">
        {news_articles.map((article, index) => (
          <a
            key={index}
            href={article.url}
            target="_blank"
            rel="noopener noreferrer"
            className="news-item"
          >
            <img src={article.img} alt={article.title} />
            <div>
                <h3>{article.title}</h3>
                <p>{article.description}</p>
            </div>
          </a>
        ))}
      </div>
      <img className="image-pop-and-run" src={Gary} alt="gary" />

    </div>
  );
};

export default NewsPage
